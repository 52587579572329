import axios from 'axios';
import { cloneDeep, snakeCase, camelCase, isPlainObject } from 'lodash';
import { Deserializer, Serializer } from 'jsonapi-serializer';

export const handleCsrfToken = {
  /* istanbul ignore next */
  assignCsrfToken() {
    const csrfMeta = document.querySelector("meta[name='csrf-token']");
    if (csrfMeta) {
      const csrfToken = csrfMeta.getAttribute('content');
      axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    }
  },
};

export function toSnakeCase(object) {
  if (Array.isArray(object)) return object.map((obj) => toSnakeCase(obj));
  else if (isPlainObject(object)) {
    return Object.keys(object).reduce(
      (result, key) => ({
        ...result,
        [snakeCase(key)]: toSnakeCase(object[key]),
      }),
      {}
    );
  }

  return object;
}

export function toCamelCase(object) {
  if (Array.isArray(object)) return object.map((obj) => toCamelCase(obj));
  else if (isPlainObject(object)) {
    return Object.keys(object).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: toCamelCase(object[key]),
      }),
      {}
    );
  }

  return object;
}

export function toPascalCase(object) {
  return `${object}`
    .replace(new RegExp(/[-_]+/, 'g'), ' ')
    .replace(new RegExp(/[^\w\s]/, 'g'), '')
    .replace(
      new RegExp(/\s+(.)(\w+)/, 'g'),
      ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
    )
    .replace(new RegExp(/\s/, 'g'), '')
    .replace(new RegExp(/\w/), (s) => s.toUpperCase());
}

export async function deserializeJsonApi(response, options = {}) {
  const responseCopy = cloneDeep(response);
  const responseData = responseCopy.data;

  delete responseCopy.data;
  responseCopy.meta = responseData?.meta || {};

  const deserializedJsonApiResponse = toCamelCase(responseCopy);
  const jsonApiData = await new Deserializer({ keyForAttribute: 'camelCase' }).deserialize(
    responseData,
    options
  );
  deserializedJsonApiResponse.data = jsonApiData;

  return deserializedJsonApiResponse;
}

export function serializeJsonApi(modelName, attributes) {
  return new Serializer(modelName, {
    attributes: Object.keys(attributes),
    keyForAttribute: 'snake_case',
  }).serialize(attributes);
}
